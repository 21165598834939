<template>
    <v-card tile elevation="4" class="pa-0">
        <v-toolbar short flat color="white">
            <v-toolbar-title class="purple--text">{{label}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="purple" @click="openCreateAccountDialog">
                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
            </v-btn>
            <v-menu offset-y left open-on-click open-on-hover close-delay="100">
                <template v-slot:activator="{ on }">
                    <v-btn icon color="purple" v-on="on">
                        <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                    </v-btn>
                    <!-- <v-tab v-on="on" class="purple--text">
                        <Avatar :attr="mainProfile" :size="36"/>
                        <font-awesome-icon icon="caret-down" class="pl-2 purple--text" fixed-width size="1x"/>
                    </v-tab> -->
                </template>
                <v-list class="ma-0 pa-0">
                    <v-list-item-group>
                    <v-list-item :to="{ name: 'system-dashboard-account-settings' }">
                        <!-- <v-list-item-content> -->
                            <v-list-item-title>Settings</v-list-item-title>
                        <!-- </v-list-item-content> -->
                    </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
        </v-toolbar>
        <!-- <v-divider></v-divider> -->
        <v-list dense nav>
            <v-list-item v-if="list.length === 0">No accounts yet.</v-list-item>
            <v-list-item v-for="item in list" v-bind:key="item.id" class="my-2" @click="onClickItem(item.id)">
                <v-list-item-content class="text-start">
                    <AccountListItem :attr="item" :link="!selectOne"></AccountListItem>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <CreateAccountDialog v-model="createAccountDialogVisible" @cancel="createAccountDialogVisible = false" @created="onCreatedAccount"/>
    </v-card>
</template>

<script>
import AccountListItem from '@/components/list-item/AccountListItem.vue';
import CreateAccountDialog from '@/components/system-dashboard/CreateAccountDialog.vue';
import { isValidName, compact } from '@/sdk/input';
// import { client } from '@/client';

export default {
    components: {
        AccountListItem,
        CreateAccountDialog,
    },

    props: {
    },

    data() {
        return {
            list: [],
            createAccountDialogVisible: false,
            // create account
            displayCreateAccount: false,
            createAccountForm: null,
            newAccountName: null,
            newAccountNameRules: [
                (v) => !!v || 'Display name is required',
                (v) => !v || isValidName(compact(v)) || 'Account name is required',
            ],
            // add account
            addAccountChoices: [],
            addAccountId: null,
        };
    },

    computed: {
        id() {
            return this.policyId;
        },
        label() {
            if (this.selectOne) {
                return 'Select an account';
            }
            switch (this.list.length) {
            case 0:
                return 'No accounts';
            case 1:
                return '1 account';
            default:
                return `${this.list.length} accounts`;
            }
        },
    },

    watch: {
        // displayCreateAccount(value) {
        //     if (value && (this.add || this.create)) {
        //         this.initAddAccountChoices();
        //     }
        // },
    },

    methods: {
        async loadAccountList() {
            try {
                this.$store.commit('loading', { loadAccountList: true });
                const response = await this.$client.main().system.getAccountList();
                if (response?.list) {
                    this.list = response.list;
                }
            } catch (err) {
                console.error('loadAccountList failed', err);
            } finally {
                this.$store.commit('loading', { loadAccountList: false });
            }
        },
        onClickItem(accountId) {
            // if (this.selectOne) {
            //     this.$emit('selected', { accountId });
            // }
            this.$router.push({ name: 'account-dashboard', params: { accountId } });
        },
        openCreateAccountDialog() {
            this.createAccountDialogVisible = true;
        },
        onCreatedAccount() {
            this.createAccountDialogVisible = false;
            this.loadAccountList();
        },
        // async createAccount() {
        //     this.error = false;
        //     console.log('createAccount');
        //     const request = {
        //         // name: this.name,
        //         name: this.newAccountName,
        //         // agreeToTerms: this.isAgreeToTermsChecked,
        //         // interactionId: this.interactionId, // will be present if account arrives from an existing interaction, such as clicking on a specific link to get started and we can use this to redirect the account to an appropriate location after account is created
        //     };
        //     const response = await this.$client.account.create(request);
        //     console.log('createAccount response: %o', response);
        //     const { isCreated, id, error } = response;
        //     if (isCreated) {
        //         const account = { id, ...request };
        //         this.list.push(account);
        //         this.$emit('created-account', account);
        //         this.$emit('added-account', account);
        //         this.displayCreateAccount = false;
        //         this.newAccountName = null;
        //     } else if (error) {
        //         this.error = error;
        //     } else {
        //         this.error = 'Request failed';
        //     }
        // },
        // async addAccount() {
        //     const account = { id: this.addAccountId, label: this.addAccountChoices.find((item) => item.value === this.addAccountId).text };
        //     this.list.push(account);
        //     this.$emit('added-account', account);
        //     this.displayCreateAccount = false;
        //     this.addAccountId = null;
        // },
        // async initAddAccountChoices() {
        //     // create a map of which account ids are already in the list
        //     const accountMap = {};
        //     this.list.forEach((item) => {
        //         accountMap[item.id] = true;
        //     });
        //     // when we show the add/create account dialog, load the list of available accounts to add to the policy (list of all accounts less the ones already in the policy)
        //     const result = await this.$client.service.getAccountList();
        //     console.log(`AccountViewList.vue fetch accounts result: ${JSON.stringify(result)}`);
        //     if (result && result.list) {
        //         // filter the results to remove accounts already in the policy, then convert the available accounts to a choice list for the v-select [ { text, value }, ... ]
        //         this.addAccountChoices = result.list.filter((item) => !accountMap[item.id]).map((item) => ({ text: item.name, value: item.id }));
        //     }
        // },
    },

    mounted() {
        this.loadAccountList();
    },
};
</script>
